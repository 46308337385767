module.exports = {
  Sentry: require('@sentry/browser'),
  History: require('history'),
  React: require('react'),
  ReactDom: require('react-dom'),
  ReactRedux: require('react-redux'),
  ReactRouter: require('react-router'),
  ReactRouterDom: require('react-router-dom'),
  Redux: require('redux'),
  Reselect: require('reselect'),
};

